<template>
    <transition :name="transitionName">
        <component
            :is="currentField"
            :loading="loading"
            :role="role"
            :member-id="memberId"
            :member-type="memberType"
            :roles="roles"
            @input="onInput"
            @submit="onSubmit"
        />
    </transition>
</template>

<script>
import _ from 'lodash'
import { attachAccess } from '@apps/employee/api/access'

export default {
    name: 'AccessForm',
    components: {
        roles: () => import('./components/AccessRolesField'),
        resource: () => import('./components/AccessResourceField')
    },
    computed: {
        roles() {
            const roles = _.get(this.$store.getters['system/localisation'], 'access.roles', {})

            if(this.$auth.isSuperAdmin()) {
                return roles
            }

            // delete roles.admin

            return roles
        }
    },
    data() {
        return {
            currentField: 'roles',
            role: null,
            memberId: null,
            memberType: null,
            transitionName: 'slide-left',
            loading: false
        }
    },
    methods: {
        onInput({ target, role, memberId, memberType }) {
            this.transitionName = target === 'COM_BACK' ? 'slide-right' : 'slide-left'
            this.currentField = target === 'COM_BACK' ? 'roles' : 'resource'
            this.role = target === 'COM_BACK' ? null : role
            this.memberId = target === 'COM_BACK' ? null : memberId
            this.memberType = target === 'COM_BACK' ? null : memberType
        },
        onSubmit(data) {
            this.loading = true

            attachAccess(data)
                .then(() => {
                    this.$emit('submit', data)
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>

<style lang=scss></style>
