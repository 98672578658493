<template>
    <content-wrapper>
        <w-data-table
            :loading="loading"
            :title="$trans('Access settings')"
            icon="PERMISSIONS"
            :headers="headers"
            itemKey="uuid"
            :items="itemsComputed"
            hide-default-header
            :hide-default-footer="items.length < 26"
        >
            <template #actions>
                <v-btn
                    icon
                    color="primary"
                    @click="onShowForm"
                >
                    <w-icon value="ADD"/>
                </v-btn>
            </template>

            <template #item.member="{ item }">
                <div class="text-truncate mt-2 mt-lg-0 font-weight-bold">{{ item['member_name'] }}</div>
                <div class="caption d-lg-none d-inline-block mb-2 mb-lg-0">
                    <span class="font-weight-bold">{{ $trans(`access.roles.${item.role}.label`) }}:</span>
                    {{ item['resource_title'] }}
                </div>
                <div class="d-none d-lg-block caption">{{ item['member_email'] }}</div>
            </template>

            <template #item.resource="{ item }">
                <div>{{ item['resource_title'] }}</div>
                <div class="caption">{{ item['resource_hint'] }}</div>
            </template>

            <template #item.role="{ item }">
                <div>{{ $trans(`access.roles.${item.role}.label`) }}</div>
<!--                <div class="caption">{{ $trans(`access.roles.${item.role}.hint`) }}</div>-->
            </template>

            <template #item.actions="{ item }">
                <v-btn
                    color="error"
                    :text="!isMobile"
                    :icon="isMobile"
                    :small="!isMobile"
                    @click="onDelete(item)"
                >
                    <w-icon
                        value="CLOSE"
                        :left="!isMobile"
                        :small="!isMobile"
                    />
                    <span v-if="!isMobile">{{ $trans('Close access') }}</span>
                </v-btn>

            </template>
        </w-data-table>
        <w-aside-drawer
            v-model="isAsideDrawer"
            :title="asideTitle"
        >
            <div class="pa-4">
                <access-form
                    :key="`access-form-${formKey}`"
                    @submit="onSubmit"
                />
            </div>
        </w-aside-drawer>
    </content-wrapper>
</template>

<script>
import AccessForm from '@apps/employee/views/Management/components/AccessForm'
import { detachAccess, getAccessCollection } from '@apps/employee/api/access'
import ConfirmationSets from '@/utils/ConfirmationSets'
import ContentWrapper from '@/components/ContentWrapper'
import hasCurrentSchoolPropertyMixin from '@apps/school/mixins/hasCurrentSchoolPropertyMixin'
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'

export default {
    name: 'AccessManage',
    mixins: [
        mobileBreakpointChecker,
        hasCurrentSchoolPropertyMixin
    ],
    components: { ContentWrapper, AccessForm },
    computed: {
        headers() {
            return [
                {
                    text: this.$trans('Member'),
                    value: 'member'
                },
                {
                    text: this.$trans('Title'),
                    value: 'resource'
                },
                {
                    text: this.$trans('Role'),
                    value: 'role'
                },
                {
                    align: 'right',
                    value: 'actions'
                }
            ]
        },
        asideTitle() {
            return this.$trans('Add access')
        },
        itemsComputed() {
            if(!this.$auth.isAdmin()) {
                return this.items
            }

            if(!this.currentSchool) return []

            return this.items.filter(o => {
                return o.resource_relations.school.uuid === this.currentSchool.uuid
            })
        }
    },
    watch: {
        isAsideDrawer(state) {
            if (state) {
                this.formKey++
            }
        }
    },
    data() {
        return {
            formKey: 1,
            loading: false,
            isAsideDrawer: false,
            items: [],
            fieldBaseBindings: {
                outlined: true,
                dense: true,
                required: true
            }
        }
    },
    mounted() {
        this.fetchAccessCollection()
    },
    methods: {
        async fetchAccessCollection() {
            try {
                this.loading = true

                const accessResponse = await getAccessCollection()
                this.items = accessResponse.data.data

                // console.log(accessResponse.data)
            } finally {
                this.loading = false
            }
        },
        onShowForm() {
            this.isAsideDrawer = true
        },
        onSubmit(data) {
            this.isAsideDrawer = false
            this.fetchAccessCollection()
        },
        async onDelete(item) {
            const isConfirm = await this.$confirm(ConfirmationSets.deleteAndCannotUndoneSet({
                title: `${this.$trans('Close access')}`
            }))

            if (!isConfirm) {
                return
            }

            this.loading = true

            try {
                await detachAccess(item)
                await this.fetchAccessCollection()
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang=scss>

</style>
