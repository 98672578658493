import request from '@/plugins/request'

export const access = data => {
    const options = {
        method: 'POST',
        url: 'access',
        data: data
    }

    return request(options)
}

export const getAccessCollection = uuid => {
    const options = {
        method: 'POST',
        url: 'access',
        data: {
            action: 'accessCollection',
            uuid
        }
    }

    return request(options)
}

export const fetchAccessMemberCollection = searchBy => {
    const options = {
        method: 'POST',
        url: 'access',
        data: {
            action: 'memberCollection',
            searchBy
        }
    }

    return request(options)
}

export const fetchAccessResourceCollection = ({ role, searchBy }) => {
    const options = {
        method: 'POST',
        url: 'access',
        data: {
            action: 'resourceCollection',
            role,
            searchBy
        }
    }

    return request(options)
}

export const attachAccess = (data) => {
    const options = {
        method: 'POST',
        url: 'access',
        data: {
            action: 'attachAccess',
            ...data
        }
    }

    return request(options)
}

export const detachAccess = (data) => {
    const options = {
        method: 'POST',
        url: 'access',
        data: {
            action: 'detachAccess',
            ...data
        }
    }

    return request(options)
}
