var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-wrapper',[_c('w-data-table',{attrs:{"loading":_vm.loading,"title":_vm.$trans('Access settings'),"icon":"PERMISSIONS","headers":_vm.headers,"itemKey":"uuid","items":_vm.itemsComputed,"hide-default-header":"","hide-default-footer":_vm.items.length < 26},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":_vm.onShowForm}},[_c('w-icon',{attrs:{"value":"ADD"}})],1)]},proxy:true},{key:"item.member",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate mt-2 mt-lg-0 font-weight-bold"},[_vm._v(_vm._s(item['member_name']))]),_c('div',{staticClass:"caption d-lg-none d-inline-block mb-2 mb-lg-0"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$trans(("access.roles." + (item.role) + ".label")))+":")]),_vm._v(" "+_vm._s(item['resource_title'])+" ")]),_c('div',{staticClass:"d-none d-lg-block caption"},[_vm._v(_vm._s(item['member_email']))])]}},{key:"item.resource",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item['resource_title']))]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(item['resource_hint']))])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$trans(("access.roles." + (item.role) + ".label"))))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","text":!_vm.isMobile,"icon":_vm.isMobile,"small":!_vm.isMobile},on:{"click":function($event){return _vm.onDelete(item)}}},[_c('w-icon',{attrs:{"value":"CLOSE","left":!_vm.isMobile,"small":!_vm.isMobile}}),(!_vm.isMobile)?_c('span',[_vm._v(_vm._s(_vm.$trans('Close access')))]):_vm._e()],1)]}}])}),_c('w-aside-drawer',{attrs:{"title":_vm.asideTitle},model:{value:(_vm.isAsideDrawer),callback:function ($$v) {_vm.isAsideDrawer=$$v},expression:"isAsideDrawer"}},[_c('div',{staticClass:"pa-4"},[_c('access-form',{key:("access-form-" + _vm.formKey),on:{"submit":_vm.onSubmit}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }